import React, {useState, useEffect} from 'react';
import type { DescriptionsProps } from 'antd';
import { Button, Descriptions, Empty, FloatButton, List, message } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import '../styles/List.css'
import copy from 'copy-to-clipboard'


// 主页面下的结果列表
const TypeList = ({keyword}) => {
  
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);
  const [emptyTxt, setEmptyTxt] = useState("尚未查询...");
  const [loading, setLoading] = useState(0);
  const [title, setTitle] = useState("");
  const [copyText, setCopyText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();


  const description = <span>{emptyTxt}</span>

  const [descItems, setDescItems] = useState<DescriptionsProps['items']>([]);
  
  useEffect(() => {
    if(keyword.length === 0){
      setLoading(0);
      setEmptyTxt("型号不能为空...");
      return;
    }
    const callbackName = 'jQuery36207258519271110546_' + Date.now();
    var url = 'https://ccpce-cn.consumer.huawei.com/ccpcmd/services/dispatch/secured/CCPC/EN/ccpc/queryNewCommodityList/1000';
    url += '?jsonp=' + callbackName;
    url += '&productLevel=lv5';
    url += '&keyword=' + keyword;
    url += '&sparePartFlag=Y';
    url += '&channelCode=WEBSITE';
    url += '&countryCode=CN';
    url += '&langCode=zh-cn';
    url += '&siteCode=zh_CN';
    url += '&_=' + Date.now();

    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);

    window[callbackName] = (d) => {
      // console.log(d);

      if(d.responseCode === "200" && d.responseDesc === 'success') {
        const list = d.responseData.productList
        setData(list)
        if(list.length === 0){
          setLoading(0);
          setEmptyTxt("未查询到...");
        }else{
          setLoading(1);
        }
      }else {
        setLoading(0);
        setEmptyTxt("查询失败，请稍后再试...");
      }

      // 移除script标签和回调函数
      document.body.removeChild(script);
      delete window[callbackName];
    };
  }, [keyword]);

  const getV6 = (productId: string)=>{
    if(productId === null || productId === ""){
      alert('未找到该型号！');
      return;
    }
    const callbackName = 'jQuery36207258519271110546_' + Date.now();
    var url = 'https://ccpce-cn.consumer.huawei.com/ccpcmd/services/dispatch/secured/CCPC/EN/ccpc/queryNewCommodityList/1000';
    url += '?jsonp=' + callbackName;
    url += '&productLevel=lv6';
    url += '&productId=' + productId;
    url += '&keyword=' + keyword;
    url += '&sparePartFlag=Y';
    url += '&channelCode=WEBSITE';
    url += '&countryCode=CN';
    url += '&langCode=zh-cn';
    url += '&siteCode=zh_CN';
    url += '&_=' + Date.now();

    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);

    window[callbackName] = (d) => {
      if(d.responseCode === "200" && d.responseDesc === 'success') {
        const list = d.responseData.productList
        // console.log(list)
        setDetail(list)
        if(list.length === 0){
          setLoading(0);
        }else{
          setLoading(2);
        }
      }else {
        setLoading(0);
      }

      // 移除script标签和回调函数
      document.body.removeChild(script);
      delete window[callbackName];
    };
  };

  const getInfoApi = (productId: string) => {
    const url = "https://sh.shoujiweixiu.club/api/ping?productId="+productId;
    
    return fetch(url)
    .then(response => {
      return response.json();
    })
    .then(data => {
      return JSON.parse(data.result);
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    })
  };

  const getInfo = (productId: string, displayName: string) => {
    getInfoApi(productId)
    .then(result => {
      if(result["responseCode"] === '200' && result["responseDesc"] === 'success' && result["responseData"]["serviceMaClassifyList"].length > 0) {
        setTitle(displayName);
        const data: any[] = result["responseData"]["serviceMaClassifyList"]
        const items: DescriptionsProps['items'] = data.map((item, index) => {
          return {
            label: item.classifyName,
            children: (
              <>
                <div className='list'>
                  <List
                    bordered
                    dataSource={item.itemTypeInfoList}
                    renderItem={(i: any) => (
                      <List.Item className='item'>
                        <b>{i.sparePartTypeLocalDesc}</b> <br/> {i.ServiceItemPrice[0].totalPrice}元 <br/> {i.ServiceItemPrice[0].remarks}
                      </List.Item>
                    )}
                  />
                </div>
              </>
            )
          };
        });
        // console.log(items);
        var ct = "查询内容：\n";
        ct += "设备型号："+ displayName +"\n\n";
        data.forEach((item, index) => {
          var name = item.classifyName;
          var list = item.itemTypeInfoList;
          console.log(name,': ', list);
          list.forEach((l, index:number) => {
            if(index === 0){
              ct += name + ': ' + l.sparePartTypeLocalDesc + l.ServiceItemPrice[0].totalPrice + '元\n';
            }else if(index === list.length - 1) {
              ct += l.sparePartTypeLocalDesc + l.ServiceItemPrice[0].totalPrice + '元\n\n';
            }else {
              ct +=  l.sparePartTypeLocalDesc + l.ServiceItemPrice[0].totalPrice + '元\n';
            }
            if(list.length - 1 === 0){
              ct += "\n";
            }
          });
        });
        ct += "查询日期：" + new Date().toLocaleDateString() + "\n";
        setCopyText(ct);
        setDescItems(items);
        setLoading(3);
      }else {
        setLoading(0);
        setEmptyTxt("未查询到该型号数据！")
      }
    })
    .catch(error => {
      setLoading(0);
      setEmptyTxt("查询出错，稍后再试...")
      console.error('Error:', error);
    })
  }

  const copyOk = () => {
    if(copyText){
      // navigator.clipboard.writeText(copyText)

      var ct = copyText;
      const lines = ct.split('\n');
      var text = lines.join('\r\n');
      console.log(text);
      copy(text, { format:'text/plain' });  
      messageApi.info('复制成功');
    }
  }

  return (
      (
        loading === 0 ? 
        <div className="empty">
          <Empty description={description} />
        </div>
        : loading === 1 ?
        <div className='list'>
          <List
            bordered
            dataSource={data}
            renderItem={item => (
              <List.Item className='item' onClick={() => getV6(item["productId"])}>
                {(item["displayName"] as string).replace(/\([^)]*\)/g, '')}
              </List.Item>
            )}
          />
        </div>
        : loading === 2 ?
        <div className='list'>
          <FloatButton
            icon={<LeftCircleOutlined />}
            tooltip={<div>返回</div>}
            onClick={() => setLoading(1)}
          />
          <List
            bordered
            dataSource={detail}
            renderItem={item => (
              <List.Item className='item' onClick={() => getInfo(item["productId"],(item["displayName"] as string).replace(/\([^)]*\)/g, ''))}>
                {(item["displayName"] as string).replace(/\([^)]*\)/g, '')}
              </List.Item>
            )}
          />
        </div>
        : loading === 3 ?
        <div>
          {contextHolder}
          <FloatButton
            icon={<LeftCircleOutlined />}
            tooltip={<div>返回</div>}
            onClick={() => setLoading(2)}
          />
          <Button 
            type="primary" 
            style={{marginTop: 10}}
            onClick={() => copyOk()}
          >一键复制</Button>
          <Descriptions
            title={title}
            bordered
            className='list'
            items={descItems}
          />
        </div>
        : null
      )
  )
};

export default TypeList;