import React, {useState,} from 'react';

import '../styles/Home.css'
import { Input, Watermark } from 'antd';
import TypeList from './TypeList';

// 主页面
const Home = () => {

  const [phoneType,setPhoneType] = useState("");

  return (
    <Watermark content="私人项目">
      <h1>后台查询</h1>
      <Input.Search
        placeholder="输入设备型号, 如P30"
        className="input"
        onSearch={value => setPhoneType(value)}
      />
      <TypeList
        keyword={phoneType.toUpperCase()}
      />
    </Watermark>
  )
};

export default Home;